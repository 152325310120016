<template>
  <section
    v-if="variants.v1 === variant"
    class="n-section-primary tw-bg-cover tw-bg-center"
    :style="`background-image: url(${vars.backgroundImage})`"
  >
    <div
      class="tw-absolute tw-inset-0 tw-opacity-80"
      :style="`background-color: var(--c-${vars.backgroundColor});`"
    ></div>
    <h3 class="n-text-shadow tw-z-10 tw-text-center tw-text-white">
      {{ vars.titleText }}
    </h3>
  </section>

  <section
    v-if="variants.v2 === variant"
    class="n-section-primary"
    :style="`background-color: var(--c-${vars.backgroundColor});`"
  >
    <MotionGroup
      is="div"
      preset="fadeVisible"
      :duration="900"
      class="tw-flex tw-w-full tw-flex-col tw-items-center tw-justify-center"
    >
      <h2 v-if="vars.titleText" class="n-title-primary tw-z-10 tw-mb-6 tw-max-w-[700px] tw-text-center md:tw-mb-11">
        {{ vars.titleText }}
      </h2>
      <div
        v-if="vars.titleHtml"
        class="tw-z-10 tw-flex tw-max-w-full tw-flex-wrap tw-justify-center tw-text-center md:tw-max-w-[60%] lg:tw-gap-4"
        v-html="vars.titleHtml"
      ></div>
      <div class="tw-mt-6 tw-flex tw-flex-row tw-justify-center tw-gap-3" v-if="buttons">
        <nuxt-link
          v-if="buttons"
          v-for="(button, index) in buttons"
          :key="`card-btn-${index}`"
          :class="`n-${button.type}`"
          :to="button.link"
          :external="true"
        >
          {{ button.label }}
        </nuxt-link>
      </div>
    </MotionGroup>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'TextBanner',

  mixins: [ComponentMixin],

  data() {
    return {
      variants: {
        v1: 'v1',
        v2: 'v2',
      },
    };
  },

  computed: {
    buttons() {
      return this.groupedVariables.buttons;
    },
  },
});
</script>
