<template>
  <section
    v-if="variant === variants.v1"
    class="n-section-primary"
    :style="`background-color: var(--c-${vars.backgroundColor})`"
  >
    <div class="tw-flex tw-w-full tw-max-w-lg tw-flex-col tw-items-center tw-text-black lg:tw-max-w-none">
      <LibraryPopupsVideoPopup :videoUrl="currentVideoUrl" v-model:is-visible="isVisible" />
      <div
        v-html="vars.titleHtml"
        class="tw-mb-10 tw-mr-auto tw-flex tw-flex-wrap tw-items-start tw-justify-start tw-gap-x-3 tw-text-start"
      ></div>
      <Swiper
        :modules="[SwiperPagination]"
        :loop="true"
        @slideChange="onSlideChange"
        :pagination="false"
        :slides-per-view="1"
        :space-between="30"
        effect="fade"
        :clickable="true"
        :breakpoints="{
          320: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
        }"
        @swiper="onSwiper"
        class="tw-w-full tw-max-w-lg tw-bg-white !tw-p-4 lg:tw-max-w-full lg:!tw-p-0"
      >
        <SwiperSlide v-for="(review, index) in reviews" :key="`singletestimonials-review-${index}`" class="!tw-h-auto">
          <div class="tw-flex tw-h-full tw-w-full tw-flex-col">
            <div
              class="lg:tw-row tw-flex tw-h-full tw-flex-col tw-items-center tw-gap-5 tw-text-start lg:tw-m-8 lg:tw-flex-row lg:tw-gap-6 xl:tw-m-10 xl:tw-gap-12"
            >
              <library-image
                v-if="review.image && !review.video"
                :src="review.image"
                :is-ratio-free="true"
                class="lg:tw-w-1/2"
              />
              <div
                v-if="review.image && review.video"
                class="tw-relative lg:tw-w-1/2"
                @click="((isVisible = true), (currentVideoUrl = review.video))"
              >
                <library-image :src="review.image" :is-ratio-free="true" />
                <button class="tw-absolute tw-left-1/2 tw-top-1/2 -tw-translate-x-1/2 -tw-translate-y-1/2">
                  <svg width="80" height="80" viewBox="0 0 162 162" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="81" cy="81" r="81" fill="white" />
                    <polygon points="65,45 115,81 65,117" fill="black" />
                  </svg>
                </button>
              </div>
              <div class="tw-flex tw-h-full tw-flex-col tw-gap-4 lg:tw-h-auto lg:tw-w-1/2">
                <div class="tw-flex tw-flex-col tw-gap-1">
                  <div class="tw-flex tw-items-center tw-gap-2">
                    <svg
                      v-for="n in review.rating"
                      :key="`singletestimonials-star-${n}`"
                      width="24"
                      height="24"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      class="lg:tw-h-8 lg:tw-w-8"
                    >
                      <path
                        d="M10 1.25L12.472 7.5H19L13.764 10.972L15.236 17.5L10 13.444L4.764 17.5L6.236 10.972L1 7.5H7.528L10 1.25Z"
                        fill="var(--c-primary)"
                      />
                    </svg>
                  </div>
                  <h4>{{ review.title }}</h4>
                </div>
                <div class="tw-flex tw-max-h-60 tw-flex-col tw-gap-2 tw-overflow-y-auto xl:tw-max-h-80">
                  <div v-html="review.descriptions"></div>
                </div>
                <span class="tw-mt-auto">{{ review.author }}</span>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
      <div class="controls !tw-mt-8">
        <div
          data-w-id="080a9ca2-7a2d-2ca3-3b22-f42b27569daf"
          class="slide-left-arrow w-slider-arrow-left"
          :isDisabled="!canPaginateBack"
          @click="() => swiper.slidePrev()"
        >
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M16.011 3.49956C16.1418 3.6335 16.2151 3.81332 16.2151 4.00056C16.2151 4.18781 16.1418 4.36762 16.011 4.50156L8.70149 12.0001L16.011 19.4971C16.1418 19.631 16.2151 19.8108 16.2151 19.9981C16.2151 20.1853 16.1418 20.3651 16.011 20.4991C15.9474 20.5644 15.8714 20.6163 15.7874 20.6517C15.7034 20.6872 15.6132 20.7054 15.522 20.7054C15.4308 20.7054 15.3406 20.6872 15.2566 20.6517C15.1726 20.6163 15.0966 20.5644 15.033 20.4991L7.25999 12.5236C7.12347 12.3835 7.04706 12.1957 7.04706 12.0001C7.04706 11.8045 7.12347 11.6166 7.25999 11.4766L15.033 3.50106C15.0966 3.43575 15.1726 3.38383 15.2566 3.34839C15.3406 3.31294 15.4308 3.29468 15.522 3.29468C15.6132 3.29468 15.7034 3.31294 15.7874 3.34839C15.8714 3.38383 15.9474 3.43575 16.011 3.50106V3.49956Z"
              fill="#6EADAB"
            />
          </svg>
          <div class="slide-circle slider-circle-transform"></div>
        </div>
        <div
          data-w-id="080a9ca2-7a2d-2ca3-3b22-f42b27569db1"
          class="slide-right-arrow w-slider-arrow-right"
          :isDisabled="!canPaginateNext"
          @click="() => swiper.slideNext()"
        >
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M7.98901 3.49956C7.85817 3.6335 7.78491 3.81332 7.78491 4.00056C7.78491 4.18781 7.85817 4.36762 7.98901 4.50156L15.2985 12.0001L7.98901 19.4971C7.85817 19.631 7.78491 19.8108 7.78491 19.9981C7.78491 20.1853 7.85817 20.3651 7.98901 20.4991C8.0526 20.5644 8.12864 20.6163 8.21263 20.6517C8.29661 20.6872 8.38685 20.7054 8.47801 20.7054C8.56917 20.7054 8.65941 20.6872 8.74339 20.6517C8.82738 20.6163 8.90342 20.5644 8.96701 20.4991L16.74 12.5236C16.8765 12.3835 16.9529 12.1957 16.9529 12.0001C16.9529 11.8045 16.8765 11.6166 16.74 11.4766L8.96701 3.50106C8.90342 3.43575 8.82738 3.38383 8.74339 3.34839C8.65941 3.31294 8.56917 3.29468 8.47801 3.29468C8.38685 3.29468 8.29661 3.31294 8.21263 3.34839C8.12864 3.38383 8.0526 3.43575 7.98901 3.50106V3.49956Z"
              fill="#6EADAB"
            />
          </svg>
          <div class="slide-circle right"></div>
        </div>
      </div>
    </div>
  </section>

  <section
    v-else-if="variant === variants.v2"
    class="n-section-primary"
    :style="`background-color: var(--c-${vars.backgroundColor})`"
  >
    <div class="tw-flex tw-w-full tw-flex-col tw-items-center tw-text-black">
      <LibraryPopupsVideoPopup :videoUrl="currentVideoUrl" v-model:is-visible="isVisible" />
      <div
        v-html="vars.titleHtml"
        class="tw-mb-6 tw-flex tw-flex-wrap tw-items-center tw-justify-center tw-gap-x-3 tw-text-center lg:tw-mb-12"
      ></div>
      <Swiper
        :modules="[SwiperPagination]"
        :pagination="false"
        :slides-per-view="1"
        :space-between="30"
        effect="fade"
        :clickable="true"
        @slideChange="onSlideChange"
        @swiper="onSwiper"
        class="tw-w-full"
      >
        <SwiperSlide v-for="(review, index) in reviews" :key="`singletestimonials-review-${index}`" class="!tw-h-auto">
          <div class="tw-bg-white tw-p-6 lg:tw-p-4" style="border-radius: var(--rounded)">
            <div
              class="tw-flex tw-h-full tw-w-full tw-flex-col tw-items-center tw-gap-5 tw-text-center lg:tw-flex-row lg:tw-gap-12 xl:tw-gap-16"
              :class="{ 'lg:tw-flex-row-reverse': isReversedBool }"
            >
              <div
                class="tw-relative tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center lg:tw-w-1/2"
                style="aspect-ratio: 16/10"
              >
                <library-image v-if="review.image && !review.video" :src="review.image" :w-ratio="559" :h-ratio="372" />
                <div
                  v-else-if="review.video"
                  class="tw-relative tw-flex tw-w-full tw-justify-center"
                  @click="((isVisible = true), (currentVideoUrl = review.video))"
                >
                  <iframe
                    v-if="review.video"
                    class="tw-mb-6 tw-h-full tw-w-full tw-rounded-[var(--rounded)] tw-object-cover lg:tw-mb-0"
                    :src="review.video"
                    :style="vars.isVerticalBool ? 'aspect-ratio:5/8;' : 'aspect-ratio: 16/9;'"
                    frameborder="0"
                    allowfullscreen
                  ></iframe>
                </div>
              </div>
              <div class="tw-flex tw-w-full tw-flex-col tw-gap-4 tw-self-center lg:tw-w-1/2">
                <div class="tw-flex tw-h-full tw-flex-col tw-gap-6 lg:tw-h-80">
                  <div class="tw-flex tw-flex-col tw-items-start tw-gap-3 lg:tw-items-start">
                    <div class="tw-flex tw-items-center tw-justify-center tw-gap-2 lg:tw-justify-start">
                      <svg
                        v-for="n in review.rating"
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.7375 33L11.175 22.4625L3 15.375L13.8 14.4375L18 4.5L22.2 14.4375L33 15.375L24.825 22.4625L27.2625 33L18 27.4125L8.7375 33Z"
                          fill="#EB2123"
                        />
                      </svg>
                    </div>
                    <h5 class="n-title-primary tw-text-center lg:tw-text-left">{{ review.title }}</h5>
                  </div>
                  <div class="tw-flex tw-flex-col tw-gap-2 tw-text-start lg:tw-text-left">
                    <div v-html="review.descriptions"></div>
                  </div>
                  <span class="tw-text-start lg:tw-text-left">{{ review.author }}</span>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
      <div class="tw-mt-10">
        <LibrarySliderPagination
          :swiper="swiper"
          variant="v6"
          :can-paginate-back="canPaginateBack"
          :can-paginate-next="canPaginateNext"
        />
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'SingleTestimonials',

  mixins: [ComponentMixin],

  data() {
    return {
      variants: {
        v1: 'v1',
        v2: 'v2',
      },
      swiper: null,
      canPaginateBack: true,
      canPaginateNext: true,
      currentVideoUrl: '',
      isVisible: false,
    };
  },
  methods: {
    onSwiper(swiper) {
      this.swiper = swiper;
    },

    onSlideChange() {
      if (!this.swiper) return;
      const isAtStart = this.swiper.isBeginning;
      const isAtEnd = this.swiper.isEnd;
      if (isAtStart) this.canPaginateBack = false;
      else this.canPaginateBack = true;
      if (isAtEnd) this.canPaginateNext = false;
      else this.canPaginateNext = true;
    },
  },

  computed: {
    reviews() {
      return this.groupedVariables.reviews;
    },
  },

  mounted() {
    this.onSlideChange();
  },
});
</script>

<style scoped>
::-webkit-scrollbar {
  width: 2px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: var(--c-primary);
}
::-webkit-scrollbar-thumb:window-inactive {
  display: none;
}
</style>
