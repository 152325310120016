<template>
  <section
    v-if="variants.v1 === variant"
    class="n-section-secondary !tw-py-0"
    :style="`background-color: var(--c-${vars.backgroundColor})`"
  >
    <div class="tw-flex tw-w-full tw-flex-col tw-text-black lg:tw-flex-row lg:tw-justify-center">
      <div
        v-for="(count, index) in counts"
        :key="`countsection-count-${index}`"
        :class="{
          'before:tw-hidden after:tw-hidden': index === counts.length - 1,
        }"
        class="tw-group tw-relative tw-flex tw-flex-col tw-items-center tw-justify-start tw-break-all tw-py-20 tw-text-center after:tw-absolute after:tw-bottom-0 after:tw-h-px after:tw-w-[100vw] after:tw-bg-[--c-border] after:tw-content-[''] lg:tw-w-full lg:after:tw-right-0 lg:after:tw-h-[100%] lg:after:tw-w-px lg:after:tw-bg-[--c-primary] lg:after:tw-opacity-20 2xl:tw-py-24"
      >
        <NuxtImg :src="count.icon" class="tw-mb-7 tw-w-16" />
        <h3 class="tw-duration-300 group-hover:-tw-translate-y-1">
          {{ count.title }}
        </h3>
        <p class="tw-mx-6 tw-min-h-[48px] tw-duration-300 group-hover:-tw-translate-y-1">
          {{ count.description }}
        </p>
      </div>
    </div>
  </section>

  <section
    v-else-if="variants.v2 === variant"
    class="n-section-secondary"
    :style="`background-color: var(--c-${vars.backgroundColor})`"
  >
    <MotionGroup
      preset="slideVisibleBottom"
      :duration="300"
      is="div"
      class="tw-flex tw-w-full tw-flex-col tw-items-center tw-justify-center tw-gap-6 lg:tw-flex-row"
    >
      <div
        v-for="(count, index) in counts"
        :key="`countsection-count-${index}`"
        class="count-card tw-group tw-relative tw-flex tw-w-full tw-max-w-xl tw-flex-col tw-items-center tw-bg-white tw-px-10 tw-py-6 tw-text-center tw-duration-500 hover:tw-bg-[var(--c-input)]"
        style="border-radius: var(--rounded)"
      >
        <NuxtImg :src="count.icon" class="spin-icon tw-mb-5 tw-w-16" />
        <h2
          class="count-number tw-mb-2 tw-duration-300 group-hover:-tw-translate-y-2"
          :style="{ color: 'var(--c-secondary)' }"
        >
          {{ count.title }}
        </h2>
        <p
          class="tw-min-h-[48px] tw-text-sm tw-duration-300 group-hover:-tw-translate-y-2"
          :style="{ color: 'var(--c-text-primary)' }"
        >
          {{ count.description }}
        </p>
      </div>
    </MotionGroup>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'CountSection',

  mixins: [ComponentMixin],

  data() {
    return {
      variants: {
        v1: 'v1',
        v2: 'v2',
      },
    };
  },

  computed: {
    counts() {
      return this.groupedVariables.counts;
    },
  },

  mounted() {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (this.variants.v1 === this.variant) {
            this.animateCounts();
          } else if (this.variants.v2 === this.variant) {
            this.animateCountsV2();
          }
          observer.disconnect();
        }
      });
    });

    observer.observe(this.$el);
  },

  methods: {
    animateCounts() {
      this.groupedVariables.counts.forEach((count, index) => {
        const element = this.$el.querySelectorAll('h3')[index];
        this.animateValue(element, 0, count.title, 2000, count.prefix, count.suffix);
      });
    },

    animateCountsV2() {
      this.groupedVariables.counts.forEach((count, index) => {
        const element = this.$el.querySelectorAll('h2')[index];
        this.animateValue(element, 0, count.title, 2700);
      });
    },

    animateValue(element, start, end, duration, prefix = '', suffix = '') {
      let startTime = null;

      const easeOutQuad = (t) => {
        return t * (2 - t); //
      };

      const step = (timestamp) => {
        if (!startTime) startTime = timestamp;
        const elapsed = timestamp - startTime;
        const progress = Math.min(elapsed / duration, 1);
        const easedProgress = easeOutQuad(progress);

        element.innerText = prefix + Math.floor(easedProgress * (end - start) + start).toLocaleString() + suffix;

        if (progress < 1) {
          window.requestAnimationFrame(step);
        }
      };

      window.requestAnimationFrame(step);
    },
  },
});
</script>

<style scoped>
.count-section:last-child::after {
  width: 0px;
}

.count-card:hover .spin-icon {
  animation: spin 0.5s linear normal;
}

@keyframes spin {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}
</style>
