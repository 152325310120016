<template>
  <section
    v-if="variant === variants.v1"
    class="n-section-primary"
    :style="`background-color: var(--c-${vars.backgroundColor}); color: ${vars.textColor ? `var(--c-${vars.textColor})` : 'var(--c-text-primary)'}`"
  >
    <div class="tw-grid tw-max-w-2xl tw-grid-cols-1 tw-gap-8 xl:tw-max-w-none xl:tw-grid-cols-12 xl:tw-gap-20">
      <div class="tw-flex tw-flex-1 tw-flex-col tw-gap-8 xl:tw-col-span-6 xl:tw-gap-14">
        <div v-html="vars.titleHtml" class="tw-text-left"></div>

        <div class="tw-flex tw-flex-col tw-gap-5">
          <details
            v-for="(faq, index) in faqs"
            :key="index"
            :open="index === openIndex"
            class="tw-overflow-hidden"
            style="border-radius: var(--rounded)"
          >
            <summary
              class="tw-group tw-flex tw-cursor-pointer tw-items-center tw-justify-between tw-bg-white tw-px-7 tw-py-4 tw-text-start tw-outline-none tw-duration-300 hover:tw-bg-[var(--c-primary)] hover:tw-text-[var(--c-text-white)]"
              @click.prevent="openIndex = openIndex === index ? 0 : index"
              style="border-radius: var(--rounded)"
            >
              <h6 class="tw-text-star tw-w-full">
                {{ faq.title }}
              </h6>
              <span v-if="openIndex === index">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M19 12.998H5V10.998H19V12.998Z" fill="white" />
                </svg>
              </span>
              <span v-else-if="openIndex != index"
                ><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M19 12.998H13V18.998H11V12.998H5V10.998H11V4.99799H13V10.998H19V12.998Z"
                    fill="black"
                    class="tw-duration-300 group-hover:tw-fill-white"
                  />
                </svg>
              </span>
            </summary>

            <small
              class="tw-block tw-max-h-0 tw-bg-white tw-px-4 tw-pb-10 tw-pt-5 tw-transition-all tw-duration-1000"
              :class="{ '!tw-max-h-[600px]': openIndex === index }"
              style="border-bottom-right-radius: var(--rounded); border-bottom-left-radius: var(--rounded)"
            >
              {{ faq.description }}
            </small>
          </details>
        </div>
      </div>

      <div
        class="tw-order-first tw-self-center tw-overflow-hidden tw-object-cover xl:tw-order-none xl:tw-col-span-6 xl:tw-row-span-2"
        :class="{ 'xl:tw-col-start-1 xl:tw-row-start-1': vars.isReversedBool }"
        style="border-radius: var(--rounded)"
      >
        <LibraryImage
          v-if="currentFAQPhoto && !vars.videoUrl"
          :src="currentFAQPhoto"
          :alt="vars.titleText"
          class="tw-object-cover"
          style="border-radius: var(--rounded)"
          :h-ratio="2"
          :w-ratio="3"
        />
        <library-video-embed
          v-if="vars.videoUrl"
          :video-url="vars.videoUrl"
          :cover-photo="vars.photoImage"
          :title="vars.titleText"
          :is-popup="true"
        />
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'FAQSection',

  mixins: [ComponentMixin],

  data() {
    return {
      openIndex: 0,
      variants: {
        v1: 'v1',
      },
    };
  },

  computed: {
    faqs() {
      return this.groupedVariables.faqs;
    },

    currentFAQPhoto() {
      if (this.openIndex !== null || this.faqs[this.openIndex].photo) {
        return this.faqs[this.openIndex].photo;
      }
      return this.vars.photoImage;
    },
  },
});
</script>

<style scoped>
details[open] > summary {
  background-color: var(--c-primary) !important;
  color: var(--c-text-white) !important;
}

details > summary:hover {
  background-color: var(--c-primary) !important;
  color: var(--c-text-white) !important;
}

details {
  overflow: hidden;
}

details[open] p {
  animation: animateUp 0.5s linear forwards;
}

@keyframes animateUp {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

summary.no-arrow {
  list-style: none;
}

summary::-webkit-details-marker {
  display: none;
}

summary::before {
  content: '';
}
</style>
