<template>
  <section
    v-if="variants.v1 === variant"
    class="n-section-primary"
    :style="`background-color: var(--c-${vars.backgroundColor})`"
  >
    <div
      class="tw-flex tw-flex-col tw-items-center tw-justify-between tw-gap-10 xl:tw-gap-24 2xl:tw-flex-row"
      :class="{ 'xl:tw-flex-row-reverse': vars.isReversedBool }"
    >
      <div class="tw-flex tw-flex-col tw-p-0">
        <div v-html="vars.titleHtml" class="tw-mb-6 tw-flex tw-gap-4 tw-text-white"></div>
        <div class="tw-flex tw-flex-col tw-gap-4">
          <p
            v-for="(item, index) in descriptions"
            :key="`ourvaluations-description-${index}`"
            class="tw-max-w-2xl !tw-text-white"
          >
            {{ item.description }}
          </p>
        </div>
      </div>
      <div class="tw-flex tw-w-auto tw-flex-col tw-justify-center tw-gap-6 md:tw-flex-row">
        <div
          v-for="(valuation, index) in valuations"
          :key="`ourvaluations-valuation-${index}`"
          class="n-card tw-flex tw-min-w-[300px] tw-max-w-xl tw-flex-[1_1_40%] tw-flex-col tw-items-center tw-justify-start tw-border-b-8 tw-border-[var(--c-primary)] tw-bg-white tw-text-black xl:tw-h-auto xl:tw-w-full xl:tw-flex-[1_1_20%]"
        >
          <LibraryImage :src="valuation.photo" :h-ratio="10" :w-ratio="16" />
          <div
            class="tw-mt-2 tw-flex tw-h-full tw-w-full tw-flex-col tw-items-start tw-justify-stretch tw-gap-5 tw-p-5 sm:tw-p-8 xl:tw-pb-2 xl:tw-pt-6"
          >
            <h4 class="tw-line-clamp-2 xl:tw-h-[60px]">
              {{ valuation.title }}
            </h4>
            <p class="tw-mb-4 tw-line-clamp-[6]">
              {{ valuation.description }}
            </p>
            <nuxt-link
              :external="true"
              class="tw-mt-auto tw-cursor-pointer !tw-whitespace-normal tw-text-center"
              :to="valuation.button_link"
              :class="`n-${valuation.button_type} tw-mb-4 tw-mt-auto`"
              >{{ valuation.button_label }}</nuxt-link
            >
          </div>
        </div>
      </div>
    </div>
  </section>

  <section
    v-else-if="variants.v2 === variant"
    class="n-section-primary"
    :style="`background-color: var(--c-${vars.backgroundColor})`"
  >
    <MotionGroup
      is="div"
      :preset="vars.animationText ? vars.animationText : 'slideVisibleBottom'"
      :duration="700"
      class="tw-flex tw-flex-col tw-items-center tw-justify-between tw-gap-10 2xl:tw-flex-row 2xl:tw-gap-24"
    >
      <div class="tw-mx-auto tw-flex tw-w-full tw-flex-col 2xl:tw-w-1/2">
        <div v-html="vars.titleHtml" class="tw-mb-6 tw-text-left"></div>
        <div class="tw-flex tw-flex-col tw-gap-3">
          <p
            v-for="(item, index) in descriptions"
            :key="`ourvaluations-description-${index}`"
            class="tw-mb-1 tw-text-left"
            :style="{ color: 'var(--c-tertiary)' }"
          >
            {{ item.description }}
          </p>
        </div>
      </div>

      <div class="tw-grid tw-grid-cols-1 tw-gap-4 md:tw-grid-cols-2 2xl:tw-w-1/2">
        <div
          v-for="(valuation, index) in valuations"
          :key="`ourvaluations-valuation-${index}`"
          class="card-box tw-overflow-hidden tw-bg-white tw-p-4"
          style="border-radius: var(--rounded)"
        >
          <div class="tw-flex tw-h-full tw-w-full tw-flex-col tw-items-center tw-gap-6 tw-border-none">
            <div class="card-photo tw-w-full tw-overflow-hidden" style="border-radius: var(--rounded)">
              <LibraryImage :src="valuation.photo" :h-ratio="1" :w-ratio="1.5" class="tw-w-auto tw-object-cover" />
            </div>
            <div
              class="tw-absolute tw-inset-0 tw-opacity-0 tw-transition-all"
              :style="`background-color: var(--c-primary);`"
            ></div>
            <div class="tw-flex tw-w-full tw-flex-grow tw-flex-col tw-gap-4">
              <h5
                class="tw-line-clamp-2 tw-w-full tw-text-left tw-text-lg tw-font-normal"
                :style="{ color: 'var(--c-secondary)' }"
              >
                {{ valuation.title }}
              </h5>
              <p class="tw-w-full tw-text-left tw-text-base tw-leading-tight" :style="{ color: 'var(--c-tertiary)' }">
                {{ valuation.description }}
              </p>
              <div class="tw-mt-auto">
                <nuxt-link
                  :external="true"
                  :to="valuation.button_link"
                  :class="`n-${valuation.button_type}`"
                  class="tw-block tw-w-full tw-py-3 tw-text-center"
                >
                  {{ valuation.button_label }}
                </nuxt-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MotionGroup>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'FAQSection',

  mixins: [ComponentMixin],

  data() {
    return {
      variants: {
        v1: 'v1',
        v2: 'v2',
      },
    };
  },

  computed: {
    valuations() {
      return this.groupedVariables.valuations;
    },

    descriptions() {
      return this.groupedVariables.descriptions;
    },

    currentFAQPhoto() {
      if (this.openIndex !== null || this.faqs[this.openIndex].photo) {
        return this.faqs[this.openIndex].photo;
      }
      return this.vars.photoImage;
    },
  },
});
</script>

<style scoped>
details[open] > summary {
  background-color: var(--c-primary) !important;
  color: var(--c-text-white) !important;
}

details > summary:hover {
  background-color: var(--c-primary) !important;
  color: var(--c-text-white) !important;
}

details {
  overflow: hidden;
}

details[open] p {
  animation: animateUp 0.5s linear forwards;
}

@keyframes animateUp {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

summary.no-arrow {
  list-style: none;
}

summary::-webkit-details-marker {
  display: none;
}

summary::before {
  content: '';
}
</style>
