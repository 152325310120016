<template>
  <section class="n-section-primary tw-min-h-[100svh]" :style="`background-color: var(--c-${vars.backgroundColor})`">
    <div class="tw-flex tw-w-full tw-flex-col tw-items-center tw-justify-center tw-self-center tw-pt-20 tw-text-center">
      <div v-html="vars.titleHtml" class="tw-mb-10 tw-flex"></div>
      <div class="tw-flex tw-flex-col tw-bg-white" style="border-radius: var(--rounded)">
        <div class="tw-flex tw-p-6 md:tw-p-10 xl:tw-px-16">
          <library-form
            v-if="form"
            :form="form"
            version="v2"
            :component="component"
            :is-form-name-visible="vars.isFormNameVisibleBool"
          ></library-form>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'ValuationForm',

  mixins: [ComponentMixin],

  data() {
    return {
      form: null,
    };
  },

  created() {
    this.form = this.parseFormV2(this.vars.valuationForm);
  },
});
</script>

<style scoped>
::v-deep(.form) {
  text-align: start;
}
</style>
