<template>
  <section class="n-section-primary" :style="`background-color: var(--c-${vars.backgroundColor})`">
    <div class="tw-flex tw-w-full tw-flex-col tw-items-center">
      <div v-html="vars.titleHtml" class="tw-mb-6 tw-w-full tw-text-center lg:tw-mb-12"></div>

      <Swiper
        :modules="[SwiperPagination]"
        @slideChange="onSlideChange"
        :pagination="false"
        :slides-per-view="1"
        :space-between="20"
        :clickable="true"
        :breakpoints="{
          320: { slidesPerView: 1, spaceBetween: 20 },
          1024: { slidesPerView: 2, spaceBetween: 24 },
          1280: { slidesPerView: 3, spaceBetween: 24 },
        }"
        @swiper="onSwiper"
        class="tw-w-full"
      >
        <SwiperSlide v-for="(video, index) in videos" :key="`singletestimonials-video-${index}`" class="!tw-h-auto">
          <MotionGroup is="div" preset="fadeVisible" :duration="400" class="n-card tw-cursor-pointer">
            <div
              class="tw-flex tw-flex-col tw-gap-4 tw-bg-white tw-p-6 tw-duration-500 hover:tw-bg-[var(--c-input)]"
              :style="{ borderRadius: 'var(--rounded)', minHeight: '380px' }"
            >
              <div class="tw-relative tw-w-full tw-pt-[56.25%]" style="aspect-ratio: 16/10">
                <div
                  v-if="video.photo && video.video"
                  class="tw-absolute tw-inset-0 tw-overflow-hidden"
                  style="border-radius: var(--rounded)"
                >
                  <library-iframe :src="video.video" class="tw-h-full tw-w-full" :h-ratio="10" :w-ratio="16" />
                </div>
              </div>

              <div class="tw-flex tw-flex-grow tw-flex-col tw-gap-4">
                <h5 class="n-title-primary tw-line-clamp-2 tw-h-16">
                  {{ video.title }}
                </h5>
                <nuxt-link v-if="video.button_link" :class="`n-${video.button_type} tw-mt-auto`">
                  {{ video.button_label }}
                </nuxt-link>
              </div>
            </div>
          </MotionGroup>
        </SwiperSlide>
      </Swiper>
      <div class="tw-mt-10">
        <LibrarySliderPagination
          :swiper="swiper"
          variant="v6"
          :canPaginateBack="canPaginateBack"
          :canPaginateNext="canPaginateNext"
        />
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'VideoBox',

  mixins: [ComponentMixin],

  data() {
    return {
      isVisible: false,
      currentVideoUrl: '',
      swiper: null,
      canPaginateBack: false,
      canPaginateNext: true,
    };
  },

  computed: {
    videos() {
      return this.groupedVariables.videos;
    },
  },

  mounted() {
    this.onSlideChange();
  },

  methods: {
    openVideo(videoUrl: string) {
      this.currentVideoUrl = videoUrl;
      this.isVisible = true;
    },

    onSwiper(swiper) {
      this.swiper = swiper;
    },

    onSlideChange() {
      if (!this.swiper) return;
      const isAtStart = this.swiper.isBeginning;
      const isAtEnd = this.swiper.isEnd;
      if (isAtStart) this.canPaginateBack = false;
      else this.canPaginateBack = true;
      if (isAtEnd) this.canPaginateNext = false;
      else this.canPaginateNext = true;
    },
  },
});
</script>
