<template>
  <section class="tw-h-[100vh]" v-if="variants.v1 === variant">
    <video
      v-if="isMobileDevice && vars.mobileBackgroundVideo"
      :src="vars.mobileBackgroundVideo"
      class="tw-absolute tw-left-0 tw-top-0 tw-h-full tw-w-full tw-object-cover"
      autoplay
      loop
      muted
      playsinline
    ></video>
    <video
      v-else-if="vars.backgroundVideo"
      :src="vars.backgroundVideo"
      class="tw-absolute tw-left-0 tw-top-0 tw-h-full tw-w-full tw-object-cover"
      autoplay
      loop
      muted
      playsinline
    ></video>
    <div class="tw-absolute tw-inset-0 tw-bg-black tw-opacity-50"></div>
    <div class="tw-relative tw-z-10 tw-flex tw-h-full tw-items-end tw-justify-center">
      <div
        class="tw-z-100 tw-mx-2 tw-my-4 tw-flex tw-flex-col tw-gap-2 tw-text-center md:tw-mx-12 md:tw-my-12 xl:tw-gap-6"
      >
        <h1 class="hero-title tw-text-white">
          {{ vars.titleText }}
        </h1>
        <div class="tw-m-10 tw-flex tw-flex-wrap tw-items-center tw-justify-center tw-gap-3 lg:tw-gap-5">
          <nuxt-link
            :external="true"
            v-for="(button, index) in buttons"
            :key="`herosection-button-${index}`"
            :to="button.link"
            :class="`n-${button.type}`"
            >{{ button.label }}</nuxt-link
          >
        </div>
      </div>
    </div>
  </section>
  <section class="tw-h-[100svh]" v-if="variants.v2 === variant">
    <video
      v-if="isMobileDevice && vars.mobileBackgroundVideo"
      :src="vars.mobileBackgroundVideo"
      class="tw-absolute tw-left-0 tw-top-0 tw-h-full tw-w-full tw-object-cover"
      autoplay
      loop
      muted
      playsinline
    ></video>

    <video
      v-else-if="vars.backgroundVideo"
      :src="vars.backgroundVideo"
      class="tw-absolute tw-left-0 tw-top-0 tw-h-full tw-w-full tw-object-cover"
      autoplay
      loop
      muted
      playsinline
    ></video>
    <NuxtImg
      :lazy="true"
      placeholder="blur"
      v-if="vars.mobileBackgroundImage && isMobileDevice"
      :src="vars.mobileBackgroundImage"
      class="tw-absolute tw-left-0 tw-top-0 tw-h-full tw-w-full tw-object-cover"
    />
    <NuxtImg
      :lazy="true"
      placeholder="blur"
      v-else-if="vars.backgroundImage"
      :src="vars.backgroundImage"
      class="tw-absolute tw-left-0 tw-top-0 tw-h-full tw-w-full tw-object-cover"
    />
    <div class="tw-absolute tw-inset-0 tw-bg-black tw-opacity-25"></div>
    <div class="tw-relative tw-z-10 tw-flex tw-h-full tw-w-full tw-items-end tw-justify-start">
      <div
        class="tw-z-100 tw-my-4 tw-mb-8 tw-flex tw-max-w-[72rem] tw-flex-col tw-gap-2 tw-px-3 tw-py-2 tw-text-start md:tw-px-14 md:tw-py-10 xl:tw-w-[90%] xl:tw-gap-6 xl:tw-px-10"
      >
        <h1 class="tw-mb-6 tw-text-white">
          {{ vars.titleText }}
        </h1>
        <div
          class="tw-flex tw-flex-col tw-flex-wrap tw-items-center tw-justify-center tw-gap-x-6 tw-gap-y-3 min-[480px]:tw-flex-row min-[480px]:tw-justify-start md:tw-mt-0 lg:tw-gap-8"
        >
          <nuxt-link
            v-for="(button, index) in buttons"
            :key="`herosection-button-${index}`"
            :class="`n-${button.type} tw-w-full tw-justify-center min-[480px]:tw-w-auto`"
            :to="button.link"
            :external="true"
            >{{ button.label }}
          </nuxt-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'HeroSection',

  mixins: [ComponentMixin],

  data() {
    return {
      variants: {
        v1: 'v1',
        v2: 'v2',
      },
    };
  },

  computed: {
    buttons() {
      return this.groupedVariables.buttons;
    },
  },
});
</script>
